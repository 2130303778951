const ZOR_TITLE = {
  mcom: {
    notSignedIn: 'Our picks for you',
    signedIn: 'Our picks for you',
  },
  bcom: {
    notSignedIn: 'Now trending',
    signedIn: 'Our picks just for you',
  },
};

export default ZOR_TITLE;
